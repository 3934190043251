import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";
import { Link } from "react-router-dom";

import { GrEdit } from "@react-icons/all-files/gr/GrEdit";

function DemoChapterSelftest() {
  return (
    <div className="Demo_Chapter_Wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <div className="demo_Chapter_selftest d-flex">
              <div className="col-md-3 d-flex">
                <h6>Qus Time</h6>
                <time>00:40</time>
              </div>
              <div className="col-md-6">
                <ul id="chapter_pagination" className=" d-flex">
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">6</a>
                  </li>
                  <li>
                    <a href="#">7</a>
                  </li>
                  <li>
                    <a href="#">8</a>
                  </li>
                  <li>
                    <a href="#">9</a>
                  </li>
                  <li>
                    <a href="#">10</a>
                  </li>
                </ul>
              </div>

              <div className="col-md-3 ">
                <div className="Demo_Close_button_chapter d-md-flex justify-content-md-end">
                  <i>
                    <GrEdit />
                  </i>
                  <Link to="/activitychapter">
                    {" "}
                    <button id="Close_button">Close</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="demo_Chapter_question">
            <img src="\Super Admin Images\chapterque1.png" alt="" />
          </div>
        </div>
        <div className="demo_selftest_next d-md-flex justify-content-md-end">
          <Link to="/activitydemochaptera">
            {" "}
            <button>Next</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DemoChapterSelftest;
