import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";
import { Link } from "react-router-dom";



function Demo() {
  return (
    <>
      <div className="Demo_Wrapper">
        <div className="Demo_Close_button d-md-flex justify-content-md-end">
          <Link to="/activitypastpaper">
            {" "}
            <button id="Close_button">Close</button>
          </Link>
        </div>

        <div className="col-md-12 ">
          <div className="Demo_Wrapper_body  d-flex">
            <div className="col-md-2 col-sm-3 col-lg-3">
              <h2 id="demo_subject">Che</h2>
            </div>
            <div className="col-md-10 col-sm-11 col-lg-11">
              <h2 id="demo_sample_test">Science Sampling test (Past Paper)</h2>
            </div>
          </div>
          <div>
            <div
              className="col-md-10 col-sm-11 col-lg-11 "
              style={{ float: "right" }}
            >
              <div className="row justify-content-md-center " id="test_Image">
                <h2>Test A</h2>
                <img src="\Super Admin Images\Test Image.png" alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-lg-3">
              <div id="demo_keystage">
                <h4>Key Stage</h4>
                <h3 id="demo_keystage_number">2</h3>
              </div>
            </div>

            <div className="col-md-2 col-sm-3 col-lg-3">
              <div id="demo_levels">
                <h4>LEVELS</h4>
                <h3>3-5</h3>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-lg-3">
              <div id="demo_year">
                <h2>2012</h2>
              </div>
            </div>
          </div>
          <div className="demo_start_button d-md-flex justify-content-md-end">
            <Link to="/activitydemoselftest">
              <button>Start</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Demo;
