import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Myprofile.css";

import { myProfile } from "../Services";

function MyProfile() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  // const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    myProfile().then((data) => {
      debugger;
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setPhone(data.phone);
      setEmail(data.email);
      setGender(data.gender);
      setAddress(data.address);
      setCountry(data.country);
      setCity(data.city);
    });
  }, []);

  return (
    <div className="Myprofile-wrapper">
      <div className="col-md-12 d-flex">
        <h1>Welcome !</h1>
      </div>
      <div className="col-md-12">
        <p>My Details</p>
      </div>
      <div className="col-md-12">
        <div className="MyProfile_form d-flex">
          <div className="col-md-2">
            <img src="./Super Admin Images/user_profile.png" alt="" />
          </div>
          <div className="col-md-5">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              // onChange={(e) => setFirstName(e.target.value)}
              // name="firstname"
              value={firstname}
            />
            <label>Phone</label>
            <input
              type="phone"
              className="form-control"
              placeholder="Jakson"
              value={phone}
            />
            <label>Gender</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              value={gender}
            />
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              value={address}
            />
            <label>city</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              value={city}
            />
          </div>
          <div className="col-md-5">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              value={lastname}
            />
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Jakson"
              value={email}
            />
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Jakson"
            />
            <label>Country</label>
            <input
              type="text"
              className="form-control"
              placeholder="Jakson"
              value={country}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
