import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Chapterpaper.css";
import { Link } from "react-router-dom";

import { GoPrimitiveDot } from "@react-icons/all-files/go/GoPrimitiveDot";

function ChapterDemo() {
  return (
    <div className="Chapter_Demo_Wrapper">
      <div className="Chapter_Demo_Close_button d-md-flex justify-content-md-end">
        <Link to="/activitychapter">
          {" "}
          <button id="Demo_Close_button">Close</button>
        </Link>
      </div>
      <div className="col-md-12">
        <div className="col-md-4">
          <h1 id="Chapter_subject">Mathematics</h1>
        </div>
        <div className="col-md-4">
          <h1 id="Chapter_keystage">Key stage 2</h1>
        </div>

        <div className="col-md-12">
          <div className="chapter_fraction">
            <h4>Integers and fraction</h4>
            <div className="chapter_instruction">
              <h5>
                Read all the instruction before start the chapter paper test
              </h5>
              <ul>
                <li>
                  <i>
                    <GoPrimitiveDot />
                  </i>
                  &nbsp;Tortor posuere ac ut consequat semper. Cursus vitae
                  congue mauris rhoncus. Leo a
                </li>
                <li>
                  <i>
                    <GoPrimitiveDot />
                  </i>
                  &nbsp;Tortor posuere ac ut consequat semper. Cursus vitae
                  congue mauris rhoncus. Leo a
                </li>
                <li>
                  <i>
                    <GoPrimitiveDot />
                  </i>
                  &nbsp;Tortor posuere ac ut consequat semper. Cursus vitae
                  congue mauris rhoncus. Leo a
                </li>
                <li>
                  <i>
                    <GoPrimitiveDot />
                  </i>
                  &nbsp;Tortor posuere ac ut consequat semper. Cursus vitae
                  congue mauris rhoncus. Leo a
                </li>
                <li>
                  <i>
                    <GoPrimitiveDot />
                  </i>
                  &nbsp;Tortor posuere ac ut consequat semper. Cursus vitae
                  congue mauris rhoncus. Leo a
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="Chapter_start_button d-md-flex justify-content-md-end">
        <button>
          <Link to="/activitychapterselftest">Start</Link>
        </button>
      </div>
    </div>
  );
}

export default ChapterDemo;
