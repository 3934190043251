import React from "react";
import Dashboard from "../Components/Dashboard";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import "../Styles/Signin.css";

function Signout() {
  return (
    <>
      <div>
        <Dashboard />
        <div className="overlay"></div>
      </div>
      <div className="signout-card ">
        <div className=" pt-5">
          <h6>Sign Out?</h6>
          <p className="p-t">Are you sure you want to sign out</p>
          <div className="signout-btn">
            <Link to="/">
              <button
                className="button1"
                onClick={() => {
                  sessionStorage.removeItem("superAdminToken");
                }}
              >
                Yes
              </button>
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Link to="/dashboard">
              <button className="button2">Cancel</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signout;
