export const baseURL = "https://api.smartht.co.uk/api/";

export const base = "https://api.smartht.co.uk";

//ContentWriter Login API URL

export const login = "cw/login";

//My Profile Page API

export const profile ="cw/myDetails";

//Subject Management Page Api View

export const chapter = "cw/viewChapter";

//Subject Management Page Create API

export const newchapter = "cw/createChapter";

//Subject Management Country Page Api

export const country ="viewCountry";

//Subject Management ExamBoard Page Api

export const examboard ="viewExamBoard";

//Subject Management Grade/year Page Api

export const grade = "viewYear";

//Subject Management Subject Page Api

export const subject = "viewSubject";