import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./Components/Home";

//Leftbar Components
import Dashboard from "./Components/Dashboard";
import SubjectManagement from "./Components/SubjectManagement";

import Curriculum from "./Components/Curriculum";
import QandA from "./Components/QandA";
//Activity Management Components
import SelfTest from "./ActivityManagement/SelfTest";
import SelfTestCreate from "./SelfTest/SelfTestCreate";
import WorkSheet from "./ActivityManagement/WorkSheet";
import WorksheetCreate from "./WorkSheet/WorksheetCreate";
import Pastpaper from "./ActivityManagement/Pastpaper";
import PastPaperCreate from "./PastPaper/PastPaperCreate";
import Demo from "./PastPaper/Demo";
import DemoSelftest from "./PastPaper/DemoSelftest";
import PastPaperEdit from "./PastPaper/PastPaperEdit";
import Chapterpaper from "./ActivityManagement/Chapterpaper";
import ChapterDemo from "./ChapterPaper/ChapterDemo";
import DemoChapterSelftest from "./ChapterPaper/DemoChapterSelftest";
import Mocktest from "./ActivityManagement/Mocktest";
import MockButtonA from "./MockTest/MockButtonA";
import MockButtonB from "./MockTest/MockButtonB";
import MockButtonC from "./MockTest/MockButtonC";
import MyProfile from "./Pages/MyProfile";
import Notifications from "./Pages/Notifications";
import Signout from "./Pages/Signout";
import SigninPage from "./Pages/SigninPage";

import DemoSelftestA from "./PastPaper/DemoSelftestA";
import DemoChapterSelftestA from "./ChapterPaper/DemoChapterSelftestA";
import UnitCreateSyllabus from "./Curriculum/UnitCreateSyllabus";
import CreateView from "./Curriculum/CreateView";

const NavRoute = ({ component: Component, ...rest }) => (
  <div>
    <Home />
    <Component />
  </div>
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SigninPage />} />
          <Route
            path="/dashboard"
            element={<NavRoute component={Dashboard} />}
          />
          <Route
            path="/subjectmanagement"
            element={<NavRoute component={SubjectManagement} />}
          />

          <Route
            path="/activityselftest"
            element={<NavRoute component={SelfTest} />}
          />
          <Route
            path="/activityworksheet"
            element={<NavRoute component={WorkSheet} />}
          />
          <Route
            path="/activitypastpaper"
            element={<NavRoute component={Pastpaper} />}
          />
          <Route
            path="/activitychapter"
            element={<NavRoute component={Chapterpaper} />}
          />

          <Route
            path="/activitymocktest"
            element={<NavRoute component={Mocktest} />}
          />
          <Route
            path="/curriculum"
            element={<NavRoute component={Curriculum} />}
          />
          <Route path="/qanda" element={<NavRoute component={QandA} />} />
          <Route
            path="/myprofile"
            element={<NavRoute component={MyProfile} />}
          />
          <Route
            path="/notifications"
            element={<NavRoute component={Notifications} />}
          />
          <Route path="/signout" element={<NavRoute component={Signout} />} />
          <Route
            path="/activityselftestcreate"
            element={<NavRoute component={SelfTestCreate} />}
          />
          <Route
            path="/activityworksheetcreate"
            element={<NavRoute component={WorksheetCreate} />}
          />
          <Route
            path="/activitypastpapercreate"
            element={<NavRoute component={PastPaperCreate} />}
          />
          <Route path="/activitypastpaperdemo" element={<Demo />} />
          <Route path="/activitydemoselftest" element={<DemoSelftest />} />
          <Route path="/activitydemoselftesta" element={<DemoSelftestA />} />
          <Route path="/activitychapterdemo" element={<ChapterDemo />} />
          <Route path="/pastpaperedit" element={<PastPaperEdit />} />
          <Route
            path="/activitychapterselftest"
            element={<DemoChapterSelftest />}
          />
          <Route
            path="/activitydemochaptera"
            element={<DemoChapterSelftestA />}
          />
          <Route
            path="/activitymocktestbuttona"
            element={<NavRoute component={MockButtonA} />}
          />
          <Route
            path="/activitymocktestbuttonb"
            element={<NavRoute component={MockButtonB} />}
          />
          <Route
            path="/activitymocktestbuttonc"
            element={<NavRoute component={MockButtonC} />}
          />
          <Route
            path="/curriculumunit"
            element={<NavRoute component={UnitCreateSyllabus} />}
          />
          <Route
            path="/curriculumview"
            element={<NavRoute component={CreateView} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
