import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";

import { BiBookReader } from "react-icons/bi";
import { IoMdSchool } from "react-icons/io";
import {
  MdKeyboardArrowDown,
  MdDashboard,
  MdQuiz,
  MdOutlineQuiz,
} from "react-icons/md";

// import { FiUsers } from "react-icons/fi";
import { BsBook, BsBookHalf } from "react-icons/bs";
import { FaBars, FaRegUser, FaSignOutAlt } from "react-icons/fa";

import { myProfile } from "../Services";
function Home() {
  const [openslider, setOpenSlider] = useState(true);
  const [profile, setMyProfile] = useState("");
  const [hoveredItem, setHoveredItem] = useState("dashboard");
  // const resetHover = () => setHoveredItem("");

  useEffect(() => {
    myProfile().then((data) => {
      setMyProfile(data);
    });
  }, []);

  const openSubmenu = (menu) => {
    if (
      document.getElementById(menu).style.display === "" ||
      document.getElementById(menu).style.display === "none"
    ) {
      document.getElementById(menu).style.display = "block";
    } else {
      document.getElementById(menu).style.display = "none";
    }
  };

  const handleOpen = () => {
    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector(".bx-menu");
    sidebarBtn.addEventListener("click", () => {
      sidebar.classList.toggle("close");
    });
    setOpenSlider(!openslider);
  };

  return (
    <div>
      <style>
        {`
          .hovered{
            color:#fff;
          }
        `}
      </style>
      <>
        <div className="sidebar open">
          <div className="logo-details">
            <span className="logo_name">SqillUp</span>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/dashboard">
                <i className="bx bx-grid-alt">
                  {" "}
                  <MdDashboard />
                </i>
                <span className="link_name">
                  <span
                    className={
                      hoveredItem === "dashboard" ? "hovered" : undefined
                    }
                    onMouseEnter={() => setHoveredItem("dashboard")}
                    // onMouseLeave={resetHover}
                  >
                    Dashboard
                  </span>
                </span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard">
                    Dashboard
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/subjectmanagement">
                <i className="bx bx-line-chart">
                  {" "}
                  <BiBookReader />
                </i>
                <span className="link_name">
                  <span
                    className={
                      hoveredItem === "subject management"
                        ? "hovered"
                        : undefined
                    }
                    onMouseEnter={() => setHoveredItem("subject management")}
                  >
                    Subject Management
                  </span>
                </span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/subjectmanagement">
                    Subject Management
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <div
                className="iocn-link"
                onClick={() => openSubmenu("user-management")}
              >
                <a href="#">
                  <i className="bx bx-book-alt">
                    {" "}
                    <MdQuiz />
                  </i>
                  <span className="link_name">
                    {" "}
                    <span
                      className={
                        hoveredItem === "activity management"
                          ? "hovered"
                          : undefined
                      }
                      onMouseEnter={() => setHoveredItem("activity management")}
                    >
                      Activity Management
                    </span>
                  </span>
                </a>
                <i className="bx bxs-chevron-down arrow">
                  {" "}
                  <MdKeyboardArrowDown />
                </i>
              </div>
              <ul className="sub-menu" id="user-management">
                <li>
                  <a href="#" className="link_name">
                    Activity Management
                  </a>
                </li>
                <li>
                  <a href="/activityselftest">Selftest</a>
                </li>
                <li>
                  <a href="/activityworksheet">Worksheet</a>
                </li>
                <li>
                  <a href="/activitypastpaper">PastPaper</a>
                </li>
                <li>
                  <a href="/activitychapter">Chapter Paper</a>
                </li>
                <li>
                  <a href="/activitymocktest">Mock Test</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/curriculum">
                <i className="bx bx-line-chart">
                  {" "}
                  <IoMdSchool />
                </i>
                <span className="link_name">
                  <span
                    className={
                      hoveredItem === "curriculum" ? "hovered" : undefined
                    }
                    onMouseEnter={() => setHoveredItem("curriculum")}
                  >
                    Curriculum
                  </span>
                </span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/curriculum">
                    Curriculum
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/qanda">
                <i className="bx bx-line-chart">
                  {" "}
                  <BsBookHalf />
                </i>
                <span className="link_name">
                  <span
                    className={hoveredItem === "qanda" ? "hovered" : undefined}
                    onMouseEnter={() => setHoveredItem("qanda")}
                  >
                    Q&A
                  </span>
                </span>
              </a>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/qanda">
                    Q&A
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <section className="home-section">
          <div className="home-content">
            <i className="bx bx-menu" onClick={handleOpen}>
              <FaBars />
            </i>

            <div className="Topbar_Right_Side d-flex">
              {/* Notification Bell Icon Div */}
              <div className="notification ">
                <a href="/notifications">
                  {" "}
                  <img src="\Super Admin Images\Notifications.svg" alt="" />
                </a>
              </div>
              {/* My Profile Property Div */}
              <div className="row d-flex">
                <div className="My-Profile-Content">
                  <ul className="nav-links">
                    <li>
                      <div
                        className="iocn-link d-block"
                        onClick={() => openSubmenu("myprofile")}
                      >
                        <div className="My_Profile_Icon">
                          <img
                            src="./Super Admin Images/My Profile.png"
                            alt=""
                          />
                          <span>
                            <span className="Profile_link_name">
                              Hello,&nbsp;<b>{profile.first_name}</b>
                            </span>
                            <br />
                            <span className="Profile_content_name">
                              &nbsp; &nbsp;Content Writer
                            </span>
                          </span>
                          <i className="bx bxs-chevron-down arrow">
                            <MdKeyboardArrowDown />
                          </i>
                        </div>
                      </div>
                      <ul className="sub-menu " id="myprofile">
                        <li>
                          <i className="bx bx-menu">
                            <FaRegUser />
                          </i>
                          <a href="/myprofile">Profile</a>
                        </li>
                        <li>
                          <i className="bx bx-menu">
                            <FaSignOutAlt />
                          </i>
                          <a href="/signout">Sign Out</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default Home;
