import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/SubjectManagement.css";
//import ViewLesson from "../SubjectManagement/ViewLesson";
import { Editor } from "react-draft-wysiwyg";
// import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle";
import { BsThreeDotsVertical } from "@react-icons/all-files/bs/BsThreeDotsVertical";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { FaNotesMedical } from "@react-icons/all-files/fa/FaNotesMedical";
import { FaVideo, FaStickyNote } from "@react-icons/all-files/fa/FaVideo";
import { RiStickyNoteFill } from "@react-icons/all-files/ri/RiStickyNoteFill";
import { AiFillFilePpt } from "@react-icons/all-files/ai/AiFillFilePpt";
import { RiFileEditFill } from "@react-icons/all-files/ri/RiFileEditFill";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";

import {
  viewsubjectmanagementchapter,
  createsubjectmanagementchapter,
  viewCountryData,
  viewExamboardData,
  viewGradeData,
  viewSubjectData,
} from "../Services";

function SubjectManagement() {
  const [hideprofile, setHideProfile] = useState(false);
  const [view, setView] = useState(false);
  const [create, setCreate] = useState(false);
  const [tab, setTab] = useState("opendata");
  const [counter, setCounter] = useState(0);
  const [model, setModel] = useState(false);
  const [viewlesson, setViewLesson] = useState(false);
  const [openbutton, setOpenButton] = useState(false);
  const [chapterdata, setChapterData] = useState(false);
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );

  const [subjectchapterview, setSubjectChapterView] = useState("");
  const [searchdata, setSearchData] = useState("");
  const [value, setValue] = useState("");
  const [country, setCountry] = useState("");
  const [examboard, setExamBoard] = useState("");
  const [subject, setSubject] = useState("");

  useEffect(() => {
    viewsubjectmanagementchapter().then((data) => {
      setSubjectChapterView(data);
    });
    viewCountryData().then((data) => {
      setCountry(data);
    });
  }, []);

  const onChangesearch = (event) => {
    setValue(event.target.value);
    viewCountryData(event.target.value).then((data) => {
      //console.log("55555555", data, event.target.value);
      if (event.target.value.trim().length) {
        setSearchData(data);
      } else setSearchData([]);
    });
  };
  const handelLessons = (data) => {
    setValue(data.name);
    setSearchData([]);
    // navigate("/particularsubject/" + data.id + "/" + data.chapter_id);
  };

  // useEffect(() => {
  //   // console.log(editorState);
  // }, [editorState]);

  const handelHide = (e) => {
    setTab(e);
  };

  const handelOpen = (event) => {
    setHideProfile(true);
  };

  const handelView = () => {
    setView(true);
  };
  const editSubjectBack = () => {
    setView(false);
  };

  const handelCreateLesson = () => {
    setCreate(true);
  };

  const handleClickAdd = () => {
    setCounter(counter + 1);
    // console.log(counter);
  };

  const handelOpenModelPopUp = () => {
    setModel(true);
  };

  const handelcloseModal = () => {
    setCreate(false);
    setViewLesson(true);
  };

  const handelBack = () => {
    setCreate(false);
  };

  const handelCreateLessonplus = () => {
    setModel(false);
  };
  const handelOpenButton = () => {
    setOpenButton(true);
  };

  const handelViewLesson = () => {
    setChapterData(true);
  };
  // const [create, setCreate] = useState(false);
  const [description, setDescription] = useState(false);
  const [notes, setNotes] = useState(false);
  const [glossary, setGlossary] = useState(false);
  const [viewedit, setViewEdit] = useState(false);

  const handelDescription = () => {
    setDescription(true);
  };
  const handelDescriptionClose = () => {
    setDescription(false);
  };
  const handelNotes = () => {
    setNotes(true);
  };
  const handelNotesClose = () => {
    setNotes(false);
  };
  const handelGlossary = () => {
    setGlossary(true);
  };
  const handelGlosseryClose = () => {
    setGlossary(false);
  };
  const handelCreateViewLesson = () => {
    setCreate(true);
  };
  const handelBackCreateViewLesson = () => {
    setChapterData(false);
  };
  const handelViewEditLesson = () => {
    setViewEdit(true);
  };
  const viewLessonedit = () => {
    setViewEdit(false);
  };

  const subjectcreateback = () => {
    setHideProfile(false);
  };
  return (
    <div className="subjectmanagement-wrapper">
      <div className="col-md-12 ">
        <div className="subjectmanagement_header d-flex">
          <div className="col-md-6">
            <h5>Chapter</h5>
          </div>
          <div className="col-md-6 d-md-flex justify-content-md-end">
            <button onClick={() => handelOpen()}>
              <FiPlusCircle />
              &nbsp;Create New Chapter
            </button>
          </div>
        </div>
      </div>
      <div className="subjectmanagement_table_data">
        <div className="col-md-12 d-flex">
          <form className="d-flex Searchbar">
            <div>
              <div className="serch-icon">
                <AiOutlineSearch />
              </div>
              <input
                className="form-control "
                type="search"
                aria-label="Search"
                placeholder="country"
                value={value}
                onChange={onChangesearch}
              />
              <div
                className="dropdown_data Searchbar"
                style={{ cursor: "pointer" }}
              >
                {searchdata.length > 0 &&
                  searchdata.slice(0, 3).map((data, index) => (
                    <p className="view_p" onClick={() => handelLessons(data)}>
                      {data.name}
                    </p>
                  ))}
              </div>
            </div>
            <div>
              <div className="serch-icon">
                <AiOutlineSearch />
              </div>
              <input
                className="form-control "
                type="search"
                aria-label="Search"
                placeholder="Examboard"
              />
            </div>

            <div>
              <div className="serch-icon">
                <AiOutlineSearch />
              </div>
              <input
                className="form-control "
                type="search"
                aria-label="Search"
                placeholder="Grade"
              />
            </div>
            <div>
              <div className="serch-icon">
                <AiOutlineSearch />
              </div>
              <input
                className="form-control "
                type="search"
                aria-label="Search"
                placeholder="Subject"
              />
            </div>
          </form>
        </div>

        <div className="subjectmanagement_table">
          <table className="table" id="subjectmanagement_table">
            <tr>
              <th></th>
              <th>Chapter name</th>
              <th>Subject</th>
              <th>Grade</th>
              <th>Examboard</th>
              <th>Action</th>
              <th>Create Lesson</th>
            </tr>
            {subjectchapterview.length > 0 &&
              subjectchapterview.map((chapter, index) => (
                <tr>
                  <td>
                    <span id="table_title">
                      <img src={chapter.image} alt="" />
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        paddingLeft: "32%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      {chapter.number}.&nbsp;&nbsp;&nbsp;{chapter.name}
                    </span>
                  </td>
                  <td>
                    <button
                      id="subjectmanagement_table_Subject"
                      style={{
                        backgroundColor: chapter.color,
                        borderColor: chapter.color,
                        color: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      {chapter?.subject.name}
                    </button>
                  </td>
                  <td>{chapter?.year.name}</td>
                  <td>{chapter?.exam_board.name}</td>
                  <td>
                    <button
                      id="subjectmanagement_table_Action"
                      onClick={() => handelView()}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    {viewlesson ? (
                      <div>
                        <button id="subjectmanagement_table_CreateLesson_view">
                          Created
                        </button>
                        &nbsp;
                        <i onClick={handelOpenButton}>
                          <BsThreeDotsVertical />
                        </i>
                      </div>
                    ) : (
                      <button
                        id="subjectmanagement_table_CreateLesson"
                        onClick={() => handelCreateLesson()}
                      >
                        Create Lesson
                      </button>
                    )}
                    {openbutton ? (
                      <div className="subjectmanagement_table_viewLesson">
                        <button onClick={handelViewLesson}>viewlesson</button>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      {/* Create New Chapter Body */}
      {hideprofile ? (
        <div className="subjectmanagement_card">
          <div className="createNewchapter_card">
            <div className="createNewchapter_back_button d-md-flex justify-content-md-end">
              <button onClick={subjectcreateback}>Back</button>
            </div>
            <div className="col-md-12">
              <p>Create New Chapter</p>
            </div>
            <div className="createNewchapter_form">
              <div className="col-md-12">
                <form className="d-flex">
                  <div className="col-md-4">
                    <label classname="form-label">Select Country</label>
                    <div>
                      <select className="form-control dropdown-toggle">
                        <option>Select Country</option>
                        <option value="uk">UK</option>
                        <option value="us">US</option>
                        <option value="india">India</option>
                        <option value="srilanka">Srilanka</option>
                      </select>
                    </div>
                    <label classname="form-label">Select Grade</label>
                    <div>
                      <select className="form-control dropdown-toggle">
                        <option>Select Grade</option>
                        <option value="g2">G2</option>
                        <option value="g3">G3</option>
                        <option value="g4">G4</option>
                        <option value="g5">G5</option>
                      </select>
                    </div>
                    <label>Chapter Number</label>
                    <div>
                      <input type="text" classname="form-control" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label classname="form-label">Select examboard</label>
                    <div>
                      <select className="form-control dropdown-toggle">
                        <option>Select examboard</option>
                        <option value="icse">ICSE</option>
                        <option value="aqa">AQA</option>
                        <option value="gcse">GCSE</option>
                        <option value="cbse">CBSE</option>
                      </select>
                    </div>
                    <label className="form-label">Select Chapter Icon</label>
                    <input type="file" className="form-control" id="formFile" />

                    <label>Chapter Name</label>
                    <div>
                      <input type="text" classname="form-control" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label classname="form-label">Select Subject</label>
                    <div>
                      <select className="form-control dropdown-toggle">
                        <option>Select Subject</option>
                        <option value="chemistry">Chemistry</option>
                        <option value="physics">Physics</option>
                        <option value="biology">Bilogy</option>
                        <option value="maths">Maths</option>
                      </select>
                    </div>
                    <label for="formFile" className="form-label">
                      Chapter Colour Code
                    </label>
                    <input
                      type="color"
                      className="form-control"
                      value="#666666"
                    />
                  </div>
                </form>
              </div>
              <div className="createNewchapter_form_button d-md-flex justify-content-md-end">
                <button id="Createnewchapter_cancel">Cancel</button>
                <button id="CreatenewChapter_save">Save</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* View Button Body */}
      {view ? (
        <div className="subjectmanagement_View_card">
          <div className="View_card">
            <div className="View_back_button d-md-flex justify-content-md-end">
              <button onClick={editSubjectBack}>Back</button>
            </div>
            <div className="col-md-12 View_card_header d-flex ">
              <div className="col-md-6">
                <p>Create New Chapter</p>
              </div>
              <div className="col-md-6">
                <button>Edit</button>
              </div>
            </div>

            <div className="View_form">
              <div className="col-md-12">
                <form className="d-flex">
                  <div className="col-md-4">
                    <label classname="form-label">Select Country</label>
                    <div>
                      <select>
                        <option>Select Country</option>
                        <option value="uk">UK</option>
                        <option value="us">US</option>
                        <option value="india">India</option>
                        <option value="srilanka">Srilanka</option>
                      </select>
                    </div>
                    <label classname="form-label">Select Grade</label>
                    <div>
                      <select>
                        <option>Select Grade</option>
                        <option value="g2">G2</option>
                        <option value="g3">G3</option>
                        <option value="g4">G4</option>
                        <option value="g5">G5</option>
                      </select>
                    </div>
                    <label>Chapter Number</label>
                    <div>
                      <input
                        type="text"
                        classname="form-control"
                        placeholder="1"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label classname="form-label">Select examboard</label>
                    <div>
                      <select>
                        <option>Select examboard</option>
                        <option value="icse">ICSE</option>
                        <option value="aqa">AQA</option>
                        <option value="gcse">GCSE</option>
                        <option value="cbse">CBSE</option>
                      </select>
                    </div>
                    <label for="formFile" className="form-label">
                      Select Chapter Icon
                    </label>
                    <input className="form-control" type="file" id="formFile" />

                    <label>Chapter Name</label>
                    <div>
                      <input
                        type="text"
                        classname="form-control"
                        placeholder="praticle model"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label classname="form-label">Select Subject</label>
                    <div>
                      <select>
                        <option>Select Subject</option>
                        <option value="chemistry">Chemistry</option>
                        <option value="physics">Physics</option>
                        <option value="biology">Bilogy</option>
                        <option value="maths">Maths</option>
                      </select>
                    </div>
                    <label for="formFile" className="form-label">
                      Chapter Colour Code
                    </label>
                    <input
                      type="color"
                      className="form-control"
                      value="#666666"
                    />
                  </div>
                </form>
              </div>
              <div className="View_form_button d-md-flex justify-content-md-end">
                <button id="View_cancel">Cancel</button>
                <button id="View_Update">Update</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Create New Lesson Html */}
      {create ? (
        <div className="CreateNewLesson_card">
          <div className="createNew_card">
            <div className="CreateNewLesson_back_button d-md-flex justify-content-md-end">
              <button onClick={handelBack}>Back</button>
            </div>
            <div className="col-md-12">
              <p>Create New Lesson</p>
            </div>
            <div className="CreateNewLesson_form">
              <div className="CreateNewLesson_form_line1">
                <div className="col-md-12 d-flex">
                  <div>
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="US"
                    />
                  </div>
                  <div>
                    <label>Exam board</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AQA"
                    />
                  </div>
                  <div>
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Chemistry"
                    />
                  </div>
                  <div>
                    <label>Grade</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="G3"
                    />
                  </div>
                  <div>
                    <label>Chapter Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="1. Praticle model"
                    />
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_form_line2">
                <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <label>Lesson number</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="col-md-8">
                    <label>Lesson Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_form_line3">
                <div className="col-md-12">
                  <label>Description</label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{ width: "94%", border: "1px solid #666666" }}
                  />
                </div>
              </div>

              <div className="CreateNewLesson_button_form">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <button
                      id="createNewLesson_Videos"
                      onClick={() => handelHide("opendata")}
                    >
                      Videos
                    </button>
                    <button
                      id="createNewLesson_ppt"
                      onClick={() => handelHide("ppt")}
                    >
                      PPT
                    </button>
                    <button
                      id="createNewLesson_sortnotes"
                      onClick={() => handelHide("video")}
                    >
                      Sort notes
                    </button>
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_URL_form">
                {tab === "opendata" ? (
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Paste the URL"
                    />
                  </div>
                ) : (
                  ""
                )}
                {tab === "ppt" ? (
                  <div className="col-md-12">
                    <input className="form-control" type="file" id="formFile" />
                  </div>
                ) : (
                  ""
                )}
                {tab === "video" ? (
                  <div className="col-md-12">
                    <input className="form-control" type="file" id="formFile" />
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{
                        width: 1080,
                        border: "1px solid #666666",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Glossery Html */}
              <div className="CreateNewLesson_Glossery_title">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <span>Glossery</span>
                  </div>
                  <div className="col-md-6">
                    <i onClick={handleClickAdd}>
                      <FiPlusCircle />
                    </i>
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_Glossery">
                <div className="col-md-12 d-flex">
                  <div className="col-md-2">
                    <div className="CreateNewLesson_Glossery_word">
                      <button>Word</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="CreateNewLesson_Glossery_Description">
                      <button>Description</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="CreateNewLesson_Glossery_Example">
                      <button>Example</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                {Array.from(Array(counter)).map((c, index) => {
                  return (
                    <div className="col-md-12 d-flex">
                      <div className="col-md-2">
                        <div className="CreateNewLesson_Glossery_word">
                          
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="CreateNewLesson_Glossery_Description">
                          
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="CreateNewLesson_Glossery_Example">
                          
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="CreateNewLesson_button_save ">
                <button
                  id="CreateNewLesson_save_btn"
                  onClick={handelOpenModelPopUp}
                >
                  Save
                </button>
                <button id="CreateNewLesson_cancel_btn">Cancel</button>
              </div>
              {model ? (
                <div className="card-content">
                  <div className="d-flex">
                    <div>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </div>
                    <div>
                      <i onClick={() => handelcloseModal()}>
                        <IoMdCloseCircle />
                      </i>
                    </div>
                  </div>
                  <h6>Lesson has been successfully Created.</h6>
                  <button onClick={() => handelCreateLessonplus()}>
                    <FiPlusCircle /> Create next Lesson
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* View Chapter Based Lesson HTML */}
      {chapterdata ? (
        <div className="subjectmanagement_ViewLesson">
          <div className="ViewLesson_data">
            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <h6>Chapter 1 - Lesson</h6>
              </div>
              <div className="col-md-6 ">
                <div className="ViewLesson_button d-md-flex justify-content-md-end">
                  <button id="create_lesson" onClick={handelCreateViewLesson}>
                    <FiPlusCircle /> Create Lesson
                  </button>
                  <button id="back" onClick={handelBackCreateViewLesson}>
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="ViewLesson_form">
              <div className="ViewLesson_table">
                <table className="table" id="ViewLesson_table">
                  <tr>
                    <th>Number</th>
                    <th>Lesson name</th>
                    <th>Des</th>
                    <th>Video</th>
                    <th>Ppt</th>
                    <th>Notes</th>
                    <th>GLossary</th>
                    <th>Action</th>
                  </tr>

                  <tr>
                    <td>1.1</td>
                    <td>The particals</td>
                    <td>
                      <span
                        id="ViewLesson_table_Description"
                        onClick={handelDescription}
                      >
                        <FaNotesMedical />
                      </span>
                    </td>
                    <td>
                      <span id="ViewLesson_table_Video">
                        <FaVideo />
                      </span>
                    </td>
                    <td>
                      <span id="ViewLesson_table_ppt">
                        <AiFillFilePpt />
                      </span>
                    </td>
                    <td>
                      <span id="ViewLesson_table_notes" onClick={handelNotes}>
                        <RiStickyNoteFill />
                      </span>
                    </td>
                    <td>
                      <span
                        id="ViewLesson_table_glossary"
                        onClick={handelGlossary}
                      >
                        <RiFileEditFill />
                      </span>
                    </td>
                    <td>
                      <button
                        id="ViewLesson_table_Action"
                        onClick={handelViewEditLesson}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </table>
                {description ? (
                  <div className="Description_card">
                    <div className="col-md-12">
                      <div className="Description_header  d-flex">
                        <div className="col-md-6">
                          <p>Description</p>
                        </div>
                        <div className="col-md-6">
                          <i onClick={handelDescriptionClose}>
                            <AiOutlineClose />
                          </i>
                        </div>
                      </div>
                      <div id="horizontal_line">{/* <hr /> */}</div>
                    </div>
                    <div className="Description_body_data">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        Lorem ipsum dolor sit amet,
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {notes ? (
                  <div className="Description_card">
                    <div className="col-md-12">
                      <div className="Description_header  d-flex">
                        <div className="col-md-6">
                          <p>Notes</p>
                        </div>
                        <div className="col-md-6">
                          <i onClick={handelNotesClose}>
                            <AiOutlineClose />
                          </i>
                        </div>
                      </div>
                      <div id="horizontal_line">
                        <hr />
                      </div>
                    </div>
                    <div className="Description_body_data">
                      <div className="col-md-12">
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat,
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {glossary ? (
                  <div className="Description_card">
                    <div className="col-md-12">
                      <div className="Description_header  d-flex">
                        <div className="col-md-6">
                          <p>Glossery</p>
                        </div>
                        <div className="col-md-6">
                          <i onClick={handelGlosseryClose}>
                            <AiOutlineClose />
                          </i>
                        </div>
                      </div>
                      <div id="horizontal_line">
                        <hr />
                      </div>
                    </div>
                    <div className="ViewLesson_Glossery">
                      <div className="col-md-12 d-flex">
                        <div className="col-md-2">
                          <div className="ViewLesson_Glossery_word">
                            <button>Word</button>
                            <input type="text" className="form-control" />
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="ViewLesson_Glossery_Description">
                            <button>Description</button>
                            <input type="text" className="form-control" />
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="ViewLesson_Glossery_Example">
                            <button>Example</button>
                            <input type="text" className="form-control" />
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {viewedit ? (
        <div className="viewEditLesson_card">
          <div className="createNew_card">
            <div className="CreateNewLesson_back_button d-md-flex justify-content-md-end">
              <button onClick={viewLessonedit}>Back</button>
            </div>
            <div className="viewEditLesson_card_header">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <p>View Lesson</p>
                </div>
                <div className="col-md-6">
                  <button>Edit</button>
                </div>
              </div>
            </div>
            <div className="CreateNewLesson_form">
              <div className="CreateNewLesson_form_line1">
                <div className="col-md-12 d-flex">
                  <div>
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="US"
                    />
                  </div>
                  <div>
                    <label>Exam board</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="AQA"
                    />
                  </div>
                  <div>
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Chemistry"
                    />
                  </div>
                  <div>
                    <label>Grade</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="G3"
                    />
                  </div>
                  <div>
                    <label>Chapter Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="1. Praticle model"
                    />
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_form_line2">
                <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <label>Lesson number</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="col-md-8">
                    <label>Lesson Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_form_line3">
                <div className="col-md-12">
                  <label>Description</label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{ width: 1080, border: "1px solid #666666" }}
                  />
                </div>
              </div>

              <div className="CreateNewLesson_button_form">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <button
                      id="createNewLesson_Videos"
                      onClick={() => handelHide("opendata")}
                    >
                      Videos
                    </button>
                    <button
                      id="createNewLesson_ppt"
                      onClick={() => handelHide("ppt")}
                    >
                      PPT
                    </button>
                    <button
                      id="createNewLesson_sortnotes"
                      onClick={() => handelHide("video")}
                    >
                      Sort notes
                    </button>
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_URL_form">
                {tab === "opendata" ? (
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Paste the URL"
                    />
                  </div>
                ) : (
                  ""
                )}
                {tab === "ppt" ? (
                  <div className="col-md-12">
                    <input className="form-control" type="file" id="formFile" />
                  </div>
                ) : (
                  ""
                )}
                {tab === "video" ? (
                  <div className="col-md-12">
                    <input className="form-control" type="file" id="formFile" />
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{
                        width: 1080,
                        border: "1px solid #666666",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Glossery Html */}
              <div className="CreateNewLesson_Glossery_title">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <span>Glossery</span>
                  </div>
                  <div className="col-md-6">
                    <i onClick={handleClickAdd}>
                      <FiPlusCircle />
                    </i>
                  </div>
                </div>
              </div>
              <div className="CreateNewLesson_Glossery">
                <div className="col-md-12 d-flex">
                  <div className="col-md-2">
                    <div className="CreateNewLesson_Glossery_word">
                      <button>Word</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="CreateNewLesson_Glossery_Description">
                      <button>Description</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="CreateNewLesson_Glossery_Example">
                      <button>Example</button>
                      <input type="text" className="form-control" />
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                {Array.from(Array(counter)).map((c, index) => {
                  return (
                    <div className="col-md-12 d-flex">
                      <div className="col-md-2">
                        <div className="CreateNewLesson_Glossery_word">
                          <button>Word</button>
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="CreateNewLesson_Glossery_Description">
                          <button>Description</button>
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="CreateNewLesson_Glossery_Example">
                          <button>Example</button>
                          <input type="text" key={c} className="form-control" />
                          <input type="text" key={c} className="form-control" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="CreateNewLesson_button_save ">
                <button id="CreateNewLesson_save_btn">Update</button>
                <button id="CreateNewLesson_cancel_btn">Cancel</button>
              </div>
              {/* {model ? (
                <div className="card-content">
                  <div className="d-flex">
                    <div>
                      <img src="\Super Admin Images\Ticmark.png" alt="" />
                    </div>
                    <div>
                      <i onClick={() => handelcloseModal()}>
                        <IoMdCloseCircle />
                      </i>
                    </div>
                  </div>
                  <h6>Lesson has been successfully Created.</h6>
                  <button onClick={() => handelCreateLessonplus()}>
                    <FiPlusCircle /> Create next Lesson
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SubjectManagement;
