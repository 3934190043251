import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/WorkSheet.css";

import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import { Link } from "react-router-dom";

function WorkSheet() {
  return (
    <div className="Worksheet_wrapper">
      <div className="col-md-12">
        <h5>WorkSheet</h5>
      </div>
      <div className="Worksheet_wrapper_table">
        <div className="col-md-12">
          <form className="d-flex Searchbar">
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="country"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Examboard"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Grade"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Subject"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Chapter"
            />
          </form>
          <div className="Worksheet_table">
            <table className="table" id="Worksheet_table">
              <tr>
                <th>Number</th>
                <th>Lesson name</th>
                <th>Demo</th>
                <th>WorkSheet</th>
                <th></th>
              </tr>

              <tr>
                <td>
                  <span>1.1</span>
                </td>
                <td>The Praticlals</td>
                <td>
                  <span>
                    <button id="worksheet_demo_button">Demo</button>
                  </span>
                </td>
                <td>
                  <Link to="/activityworksheetcreate">
                    <button id="worksheet_create_button">Create</button>
                  </Link>
                </td>
                <td>
                  <label className="toggleSwitch_worksheet nolabel">
                    <input
                      type="checkbox"
                      class="switch_1"
                      name="payment_email_notification"
                    />
                    <span>
                      <span>Draft</span>
                      <span>Publish</span>
                    </span>
                    <a></a>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkSheet;
