import React, { useState } from "react";
import DemoSelftest from "./DemoSelftest";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function PastPaperEdit() {
  const [text, setText] = useState("");
  const [buttonshow, setButtonShow] = useState("solution");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const hideImagebutton = (e) => {
    setButtonShow(e);
  };
  return (
    <>
      <div>
        <DemoSelftest />
        <div className="overlay"></div>
      </div>
      <div className="pastpaper_edit_card ">
        <div className="pastpaper_edit container-fluid ">
          <div className="col-md-12  d-flex">
            <div className="col-md-6">
              <h6>Edit Question</h6>
            </div>
            <div className="col-md-6 d-flex justify-content-md-end">
              <a href="/activitydemoselftest">
                {" "}
                <i>
                  <AiOutlineClose />
                </i>
              </a>
            </div>
          </div>
        </div>
        <div className="PastPaper_edit_form">
          <div className="PastPaper_edit_form1">
            <div className="col-md-12 d-flex">
              <div className="col-md-3">
                <label>Select Examboard</label>
                <select className="form-control dropdown-toggle">
                  <option>Select examboard</option>
                  <option value="icse">ICSE</option>
                  <option value="aqa">AQA</option>
                  <option value="gcse">GCSE</option>
                  <option value="cbse">CBSE</option>
                </select>
              </div>
              <div className="col-md-3">
                <label>Select Subject</label>
                <select className="form-control dropdown-toggle">
                  <option>Subject</option>
                  <option value="uk">Chemistry</option>
                  <option value="us">Physics</option>
                  <option value="india">Maths</option>
                  <option value="srilanka">Social</option>
                </select>
              </div>
              <div className="col-md-3">
                <label>Select grade/ level</label>
                <select className="form-control dropdown-toggle">
                  <option>Select Grade</option>
                  <option value="uk">Grade 1</option>
                  <option value="us">Grade 2</option>
                  <option value="india">Grade 3</option>
                  <option value="srilanka">Grade 4</option>
                </select>
              </div>
              <div className="col-md-3">
                <label>Year</label>
                <input type="date" className="form-control" />
              </div>
            </div>

            <br />

            <div className="col-md-12 d-flex">
              <div className="col-md-3">
                <label>Level</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Higher/foundation/level 2-3/level 5-6"
                />
              </div>
              <div className="col-md-3">
                <label>Total duration</label>
                <input
                  type="time"
                  id="appt"
                  name="appt"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>Color code</label>
                <input type="color" className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Instruction</label>
                <input type="file" className="form-control" />
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-12 d-flex">
            <div className="col-md-2">
              <div className="Worksheet_QuestionType">Question Type</div>
              <div className="Worksheet_QuestionType_data">
                {/* <div id="MCQ" onClick={() => handelHide("mcq")}>
                MCQ
              </div>
              <div id="oneword" onClick={() => handelHide("oneword")}>
                One words
              </div> */}
                <div
                  id="image"
                  style={{
                    color: "#5a5eee",
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "500",
                    paddingRight: "20px",
                  }}
                >
                  Image
                </div>
              </div>
            </div>

            <div className="col-md-10">
              <div className="Selftest_CreateNewQuestions">
                <div className="col-md-5 pt-2">
                  <h6>Edit Question</h6>
                </div>
                <div className="col-md-5 ">
                  <i
                    className="d-flex justify-content-md-end delete_edit_pastpaper"
                  >
                    <AiOutlineDelete />
                  </i>
                </div>
              </div>
              <div className="Selftest_CreateNewQuestions_data">
                <div className="Iamage_Questions_number">
                  <div className="col-md-8 d-flex ">
                    <div>
                      <label>Question Number</label>
                      <input
                        type="text"
                        placeholder="1"
                        className="form-control"
                      />
                    </div>
                    <div>
                      <label>Chapter Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="Image_Question_Entry">
                  <div className="col-md-12">
                    <label>Question ?</label>

                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{ width: 770, border: "1px solid black" }}
                    />
                  </div>
                </div>
                <div className="Image_Question_Entry">
                  <div className="col-md-12">
                    <label>Answer</label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{ width: 770, border: "1px solid black" }}
                    />
                  </div>
                </div>
                <div className="Image_Question_buttons_edit">
                  <div className="col-md-12">
                    <button
                      id="theory"
                      onClick={() => hideImagebutton("solution")}
                    >
                      Solution
                    </button>
                    <button
                      id="theory"
                      onClick={() => hideImagebutton("theory")}
                    >
                      Theory
                    </button>
                    <button
                      id="theory"
                      onClick={() => hideImagebutton("markingscheme")}
                    >
                      Marking Scheme
                    </button>
                  </div>
                </div>
                <div className="Question_Entry">
                  {buttonshow === "solution" ? (
                    <div className="col-md-12">
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{ width: 770, border: "1px solid black" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {buttonshow === "theory" ? (
                    <div className="col-md-12">
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{ width: 770, border: "1px solid black" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {buttonshow === "markingscheme" ? (
                    <div className="col-md-12">
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{ width: 770, border: "1px solid black" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="Selftest_Questions_number">
                  <div className="col-md-3">
                    <label>Marks</label>
                    <input
                      type="text"
                      placeholder="2"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="Question_Save_buttons">
                  <div className="col-md-12 d-md-flex justify-content-md-end">
                    <button id="save">Save</button>
                    <button id="next_question">Next Question</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PastPaperEdit;
