import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { MdDeleteOutline } from "react-icons/md";

function PastPaperCreate() {
  const [tab, setTab] = useState("oneword");
  const [text, setText] = useState("");
  const [buttonshow, setButtonShow] = useState("solution");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const hideImagebutton = (e) => {
    setButtonShow(e);
  };
  // const handelHide = (e) => {
  //   setTab(e);
  // };

  const [selected, setSelected] = useState("type");
  const [counter, setCounter] = useState(0);

  function handleSelectChange(event) {
    setSelected(event.target.value);
  }

  const handleClickAdd = () => {
    setCounter(counter + 1);
  };
  return (
    <div className="PastPaper_card">
      <div className="col-md-12 d-flex">
        <div className="col-md-6">
          <h6 id="PastPaper_title">PastPaper</h6>
        </div>
        <div className="col-md-6">
          <div className="PastPaper_back_button d-md-flex justify-content-md-end">
            <Link to="/activitypastpaper">
              <button>Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="PastPaper_form">
        <div className="PastPaper_form_line1">
          <div className="col-md-12 d-flex">
            <div className="col-md-3">
              <label>Select Examboard</label>
              {/* <input type="text" className="form-control" placeholder="AQA" /> */}
              <select className="form-control dropdown-toggle">
                <option>Select examboard</option>
                <option value="icse">ICSE</option>
                <option value="aqa">AQA</option>
                <option value="gcse">GCSE</option>
                <option value="cbse">CBSE</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Select Subject</label>
              {/* <input
                type="text"
                className="form-control"
                placeholder="Chemistry"
              /> */}
              <select className="form-control dropdown-toggle">
                <option>Subject</option>
                <option value="uk">Chemistry</option>
                <option value="us">Physics</option>
                <option value="india">Maths</option>
                <option value="srilanka">Social</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Select grade/ level</label>
              {/* <input type="text" className="form-control" placeholder="G3" /> */}
              <select className="form-control dropdown-toggle">
                <option>Select Grade</option>
                <option value="uk">Grade 1</option>
                <option value="us">Grade 2</option>
                <option value="india">Grade 3</option>
                <option value="srilanka">Grade 4</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Year</label>
              <input type="date" className="form-control" />
            </div>
          </div>

          <br />

          <div className="col-md-12 d-flex">
            <div className="col-md-3">
              <label>Level</label>
              <input
                type="text"
                className="form-control"
                placeholder="Higher/foundation/level 2-3/level 5-6"
              />
            </div>
            <div className="col-md-3">
              <label>Total duration</label>
              <input
                type="time"
                id="appt"
                name="appt"
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Color code</label>
              <input type="color" className="form-control" />
            </div>
            <div className="col-md-3">
              <label>Instruction</label>
              <input type="file" className="form-control" />
            </div>
          </div>
          <br />
        </div>
        <div className="col-md-12 d-flex">
          <div className="col-md-2">
            <div className="Worksheet_QuestionType">Question Type</div>
            <div className="Worksheet_QuestionType_data">
              {/* <div id="MCQ" onClick={() => handelHide("mcq")}>
                MCQ
              </div>
              <div id="oneword" onClick={() => handelHide("oneword")}>
                One words
              </div> */}
              <div
                id="image"
                style={{
                  color: "#5a5eee",
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: "500",
                  paddingRight: "20px",
                }}
              >
                Image
              </div>
              {/* <div id="fillinblanks">Fill in blanks</div>
                <div id="matchingpair">Matching pair</div>
                <div id="autocheck">Auto Check</div> */}
            </div>
          </div>
          {/* {tab === "oneword" ? (
            <div className="col-md-10">
              <div className="Selftest_CreateNewQuestions">
                Create New Question
              </div>
              <div className="Selftest_CreateNewQuestions_data">
                <div className="Selftest_Questions_number">
                  <div className="col-md-3">
                    <label>Question Number</label>
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="Question_Entry">
                  <div className="col-md-12">
                    <label>Question ?</label>
                   
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                </div>
                <div className="Question_Answer">
                  <div className="col-md-12">
                    <label>Answer</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="Question_buttons">
                  <div className="col-md-12">
                    <button id="solution">Solution</button>
                    <button id="theory">Theory</button>
                  </div>
                </div>
                <div className="Question_Entry">
                  <div className="col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                </div>
                <div className="Selftest_Questions_number">
                  <div className="col-md-3">
                    <label>Marks</label>
                    <input
                      type="text"
                      placeholder="2"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="Question_Save_buttons">
                  <div className="col-md-12 d-md-flex justify-content-md-end">
                    <button id="save">Save</button>
                    <button id="next_question">Next Question</button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          {/* {tab === "mcq" ? (
            <div className="col-md-10">
              <div className="Worksheet_CreateNewQuestions">
                Create New Question
              </div>
              <div className="Worksheet_CreateNewQuestions_data">
                <div className="worksheet_Questions_number">
                  <div className="col-md-3">
                    <label>Question Number</label>
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="Worksheet_Question_Entry">
                  <div className="col-md-12">
                    <label>Question ?</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                </div>
                <div className="Worksheet_Question_Answer">
                  <label>Answer</label>
                  <div className="col-md-12 d-flex">
                    <select value={selected} onChange={handleSelectChange}>
                      <option value="type">Type</option>
                      <option value="image">Image</option>
                      <option value="text">Text</option>
                    </select>
                    <div className="row">
                      {selected == "type" ? (
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <label className="toggleSwitch_worksheet nolabel">
                            <input
                              type="checkbox"
                              class="switch_1"
                              name="payment_email_notification"
                            />
                            <span>
                              <span>No</span>
                              <span>Yes</span>
                            </span>
                            <a></a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {selected == "text" ? (
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <label className="toggleSwitch_worksheet nolabel">
                            <input
                              type="checkbox"
                              class="switch_1"
                              name="payment_email_notification"
                            />
                            <span>
                              <span>No</span>
                              <span>Yes</span>
                            </span>
                            <a></a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {selected == "image" ? (
                        <div className="d-flex">
                          <input type="file" className="form-control" />
                          <label className="toggleSwitch_worksheet nolabel">
                            <input
                              type="checkbox"
                              class="switch_1"
                              name="payment_email_notification"
                            />
                            <span>
                              <span>No</span>
                              <span>Yes</span>
                            </span>
                            <a></a>
                          </label>
                          <i>
                            <MdDeleteOutline />
                          </i>
                        </div>
                      ) : (
                        ""
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                  {Array.from(Array(counter)).map((c, index) => {
                    return (
                      <div className="col-md-12 d-flex">
                        <select value={selected} onChange={handleSelectChange}>
                          <option value="type">Type</option>
                          <option value="image">Image</option>
                          <option value="text">Text</option>
                        </select>
                        <div className="row">
                          {selected == "type" ? (
                            <div className="d-flex">
                              <input type="text" className="form-control" />
                              <label className="toggleSwitch_worksheet nolabel">
                                <input
                                  type="checkbox"
                                  class="switch_1"
                                  name="payment_email_notification"
                                />
                                <span>
                                  <span>No</span>
                                  <span>Yes</span>
                                </span>
                                <a></a>
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {selected == "text" ? (
                            <div className="d-flex">
                              <input type="text" className="form-control" />
                              <label className="toggleSwitch_worksheet nolabel">
                                <input
                                  type="checkbox"
                                  class="switch_1"
                                  name="payment_email_notification"
                                />
                                <span>
                                  <span>No</span>
                                  <span>Yes</span>
                                </span>
                                <a></a>
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {selected == "image" ? (
                            <div className="d-flex">
                              <input type="file" className="form-control" />
                              <label className="toggleSwitch_worksheet nolabel">
                                <input
                                  type="checkbox"
                                  class="switch_1"
                                  name="payment_email_notification"
                                />
                                <span>
                                  <span>No</span>
                                  <span>Yes</span>
                                </span>
                                <a></a>
                              </label>
                              <i>
                                <MdDeleteOutline />
                              </i>
                            </div>
                          ) : (
                            ""
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="Worksheet_Add_Answer">
                  <button onClick={handleClickAdd}>Add Answer</button>
                </div>
                <div className="Question_buttons">
                  <div className="col-md-12">
                    <button id="solution">Solution</button>
                    <button id="theory">Theory</button>
                  </div>
                </div>
                <div className="Question_Entry">
                  <div className="col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                </div>
                <div className="Selftest_Questions_number">
                  <div className="col-md-3">
                    <label>Marks</label>
                    <input
                      type="text"
                      placeholder="2"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="Question_Save_buttons">
                  <div className="col-md-12 d-md-flex justify-content-md-end">
                    <button id="save">Save</button>
                    <button id="next_question">Next Question</button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}

          <div className="col-md-10">
            <div className="Selftest_CreateNewQuestions">
              Create New Question
            </div>
            <div className="Selftest_CreateNewQuestions_data">
              <div className="Iamage_Questions_number">
                <div className="col-md-8 d-flex ">
                  <div>
                    <label>Question Number</label>
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                  <div>
                    <label>Chapter Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="Image_Question_Entry">
                <div className="col-md-12">
                  <label>Question ?</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={question}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setQuestion(data);
                    }}
                    placeholder="Insert Image Only"
                  />
                </div>
              </div>
              <div className="Image_Question_Entry">
                <div className="col-md-12">
                  <label>Answer</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={answer}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAnswer(data);
                    }}
                  />
                </div>
              </div>
              <div className="Image_Question_buttons">
                <div className="col-md-12">
                  <button
                    id="theory"
                    onClick={() => hideImagebutton("solution")}
                  >
                    Solution
                  </button>
                  <button id="theory" onClick={() => hideImagebutton("theory")}>
                    Theory
                  </button>
                  <button
                    id="theory"
                    onClick={() => hideImagebutton("markingscheme")}
                  >
                    Marking Scheme
                  </button>
                </div>
              </div>
              <div className="Question_Entry">
                {buttonshow === "solution" ? (
                  <div className="col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {buttonshow === "theory" ? (
                  <div className="col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {buttonshow === "markingscheme" ? (
                  <div className="col-md-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="Selftest_Questions_number">
                <div className="col-md-3">
                  <label>Marks</label>
                  <input type="text" placeholder="2" className="form-control" />
                </div>
              </div>
              <div className="Question_Save_buttons">
                <div className="col-md-12 d-md-flex justify-content-md-end">
                  <button id="save">Save</button>
                  <button id="next_question">Next Question</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastPaperCreate;
