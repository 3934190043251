import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Curriculum.css";

import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";
import { AiFillFileText } from "@react-icons/all-files/ai/AiFillFileText";
import { FcDocument } from "react-icons/fc";

import { Link } from "react-router-dom";

function Curriculum() {
  const [hideYear, setHideYear] = useState(false);

  const handleCheckBox = (event) => {
    setHideYear(event.target.checked);
  };

  return (
    <div className="Curriculum_wrapper">
      {/* Chapter toggle Data */}
      {hideYear ? (
        <div>
          <div className="Curriculum_wrapper_table">
            <div className="col-md-12 d-flex">
              <div className="col-md-10">
                <form className="d-flex Searchbar">
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="country"
                  />
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Examboard"
                  />

                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Subject"
                  />
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Grade"
                  />
                </form>
              </div>
              <div className="col-md-2 d-md-flex justify-content-md-end">
                <button>
                  {" "}
                  <FiPlusCircle /> Create syllabus
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2 offset-md  Curriculum_year-tag">
            <div className="switch_box2 box_2">
              {hideYear ? <label>Chapter</label> : <label>Unit</label>}
              <input
                type="checkbox"
                className="switch_2"
                onClick={handleCheckBox}
              />
            </div>
          </div>
          <div className="Curriculum_body">
            <div className="col-md-12">
              <div className="Chapter_curriculum_table2">
                <table className="table" id="Chapter_curriculum_table2">
                  <tr>
                    <th>Chapter no</th>
                    <th>Chapter name</th>
                    <th>Lesson Plan</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>1</td>

                    <td>State of matter</td>

                    <td>
                      <i>
                        <AiFillFileText />
                      </i>
                    </td>

                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>

                    <td>Element Compound and mixtures</td>

                    <td>
                      <i>
                        <AiFillFileText />
                      </i>
                    </td>

                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>

                    <td>Element Compound and mixtures</td>

                    <td>
                      <i>
                        <AiFillFileText />
                      </i>
                    </td>

                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>

                    <td>Element Compound and mixtures</td>

                    <td>
                      <i>
                        <AiFillFileText />
                      </i>
                    </td>

                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Unit toggle Data
        <div>
          <div className="Curriculum_wrapper_table">
            <div className="col-md-12 d-flex">
              <div className="col-md-10">
                <form className="d-flex Searchbar">
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="country"
                  />
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Examboard"
                  />

                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Subject"
                  />
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control "
                    type="search"
                    aria-label="Search"
                    placeholder="Grade"
                  />
                </form>
              </div>
              <div className="col-md-2 d-md-flex justify-content-md-end">
                <Link to="/curriculumunit">
                  <button>
                    {" "}
                    <FiPlusCircle /> Create syllabus
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-2 offset-md  Curriculum_year-tag">
            <div className="switch_box2 box_2">
              {hideYear ? <label>Chapter</label> : <label>Unit</label>}
              <input
                type="checkbox"
                className="switch_2"
                onClick={handleCheckBox}
              />
            </div>
          </div>
          <div className="Curriculum_body">
            <div className="col-md-12 d-flex">
              <div className="col-md-4">
                <div className="curriculum_table">
                  <table className="table" id="curriculum_table">
                    <tr>
                      <th>Unit name</th>
                    </tr>
                    <tr className="active">
                      <td>Unit 1: principle of chemistry</td>
                    </tr>
                    <tr>
                      <td>Unit 2: Inorganic chemistry</td>
                    </tr>
                    <tr>
                      <td>Unit 3: physical chemistry</td>
                    </tr>
                    <tr>
                      <td>Unit 4: Inorganic chemistry</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-8">
                <div className="curriculum_table2">
                  <table className="table" id="curriculum_table2">
                    <tr>
                      <th>Chapter no</th>
                      <th>Chapter name</th>
                      <th>Lesson Plan</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>1</td>

                      <td>State of matter</td>

                      <td>
                        <i>
                          <AiFillFileText />
                        </i>
                      </td>

                      <td>
                        <Link to="/curriculumview">
                          <button>View</button>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>

                      <td>Element Compound and mixtures</td>

                      <td>
                        <i>
                          <AiFillFileText />
                        </i>
                      </td>

                      <td>
                        <Link to="/curriculumview">
                          <button>View</button>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>

                      <td>Element Compound and mixtures</td>

                      <td>
                        <i>
                          <AiFillFileText />
                        </i>
                      </td>

                      <td>
                        <Link to="/curriculumview">
                          <button>View</button>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>

                      <td>Element Compound and mixtures</td>

                      <td>
                        <i>
                          <AiFillFileText />
                        </i>
                      </td>

                      <td>
                        <Link to="/curriculumview">
                          <button>View</button>
                        </Link>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Curriculum;
