import axios from "axios";

import {
  baseURL,
  base,
  login,
  profile,
  chapter,
  country,
  examboard,
  grade,
  subject,
  newchapter,
} from "../Constants";

const headers = {
  Authorization: "Bearer " + sessionStorage.getItem("contentWriterToken"), //the token is a variable which holds the token
};

//Login Page Content Writer

export const contentwriterLogin = async (requestData) => {
  return await axios
    .post(baseURL + login, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
//My Profile Page Content Writer
export const myProfile = async (requestData) => {
  return await axios
    .post(baseURL + profile, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
// Subject Management Chapter Table View API
export const viewsubjectmanagementchapter = async (requestData) => {
  return await axios
    .post(baseURL + chapter, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
// Subject Management Chapter Table Create API
export const createsubjectmanagementchapter = async (requestData) => {
  return await axios
    .post(baseURL +   newchapter, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
//Country Data
export const viewCountryData = async (requestData) => {
  return await axios
    .post(baseURL +  country, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
//ExamBoard data
export const viewExamboardData = async (requestData) => {
  return await axios
    .post(baseURL +  examboard, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
//Grade/Year data
export const viewGradeData = async (requestData) => {
  return await axios
    .post(baseURL +  grade, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};
//Subject Data
export const viewSubjectData = async (requestData) => {
  return await axios
    .post(baseURL + subject, requestData, {
      headers,
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((error) => {
      throw error;
    });
};