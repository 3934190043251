import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";

function Dashboard() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var monthName = months[d.getMonth()];
  const current = new Date();
  const date = `${current.getDate()}`;
  const time = new Date().toLocaleTimeString();

  return (
    <div className="dashboard-wrapper">
      <div className="row">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <h1>Welcome !</h1>
          </div>
          <div className="col-md-6 d-md-flex justify-content-md-end">
            <div className="col-md-3 ">
              <button>
                {date}
                <sup>th</sup>&nbsp;
                <sub>{monthName}</sub>
              </button>
            </div>
            <div className="col-md-3 d-md-block justify-content-md-end">
              <h5 id="time">{time}</h5>
              <h5 id="Country">Srilanka</h5>
              <p>Matara</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
