import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";

import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { BiUpload } from "@react-icons/all-files/bi/BiUpload";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { TiTick } from "@react-icons/all-files/ti/TiTick";

// import {IoClose} from 'react-icons/io';
import { Link } from "react-router-dom";

function Pastpaper() {
  const [hidebutton, setHideButton] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const handelButton = () => {
    setHideButton(true);
  };
  const handelUploadFile = () => {
    setOpenFile(true);
  };
  const handelCloseModal = () => {
    setOpenFile(false);
  };
  return (
    <div className="Pastpaper_wrapper">
      <div className="col-md-12">
        <h5>Pastpaper</h5>
      </div>
      <div className="Pastpaper_wrapper_table">
        <div className="col-md-12">
          <form className="d-flex Searchbar">
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="country"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Examboard"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Subject"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Level"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Year"
            />
          </form>
          <div className="Pastpaper_table">
            <table className="table" id="Pastpaper_table">
              <tr>
                <th>Subject</th>
                <th>Exam board</th>
                <th>Year</th>
                <th>Upload file</th>
                <th>Action</th>
                <th>Past paper</th>
                <th></th>
              </tr>

              <tr>
                <td>
                  <span id="subject">Physics</span>
                </td>
                <td>GCSE</td>
                <td>2018</td>
                <td onClick={handelUploadFile}>
                  <i id="Upload_file">
                    <BiUpload />
                  </i>
                </td>
                <td>
                  <Link to="/activitypastpaperdemo"><button id="demo_button">Demo</button></Link>
                </td>
                <td>
                 <Link to="/activitypastpapercreate"> <button id="demo_button">Create</button></Link>
                </td>
                <td onClick={handelButton}>
                  {hidebutton ? (
                    <td>
                      <button id="publish_button">Publish</button>
                    </td>
                  ) : (
                    <td>
                      <button id="draft_button">Draft</button>
                    </td>
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      {openFile ? (
        <div className="open_file_container container-fluid ">
          <div className="Upload_file_top ">
            <div className="col-md-12  d-flex">
              <div className="col-md-6">
                <h6>Upload file</h6>
              </div>
              <div className="col-md-6 d-flex justify-content-md-end">
                <i onClick={handelCloseModal}>
                  <AiOutlineClose />
                </i>
              </div>
            </div>
          </div>
          <div className="upload_file_input">
            <div className="col-md-12">
              <p>Chapter past paper</p>
              <input type="file" className="form-control" />{" "}
              <i>
                <AiOutlineClose />
              </i>
              <span>
                File upload Success <TiTick />
              </span>
            </div>
          </div>
          <div className="Upload_file_buttons d-md-flex justify-content-md-end">
            <button id="cancel" onClick={handelCloseModal}>
              Cancel
            </button>
            <button id="save">Save</button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Pastpaper;
