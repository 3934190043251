import React,{ useState} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Mocktest.css";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { MdDeleteOutline } from "react-icons/md";

function MockButtonC() {
  const [tab, setTab] = useState("oneword");
  const [text, setText] = useState("");
  const handelHide = (e) => {
    setTab(e);
  };

  const [selected, setSelected] = useState("type");
  const [counter, setCounter] = useState(0);

  function handleSelectChange(event) {
    setSelected(event.target.value);
  }

  const handleClickAdd = () => {
    setCounter(counter + 1);
  };
  return (
    <div className="mocktest_card">
    <div className="col-md-12 d-flex">
      <div className="col-md-6">
        <h6 id="mocktest_title">Mocktest</h6>
      </div>
      <div className="col-md-6">
        <div className="mocktest_back_button d-md-flex justify-content-md-end">
          <Link to="/activitymocktest">
            <button>Back</button>
          </Link>
        </div>
      </div>
    </div>
    <div className="PastPaper_form">
      <div className="PastPaper_form_line1">
        <div className="col-md-12 d-flex">
          <div className="col-md-3">
            <label>Select exam board</label>
            <input type="text" className="form-control" placeholder="AQA" />
          </div>
          <div className="col-md-3">
            <label>Select subject</label>
            <input
              type="text"
              className="form-control"
              placeholder="Chemistry"
            />
          </div>
          <div className="col-md-3">
            <label>Select grade</label>
            <input type="text" className="form-control" placeholder="G3" />
          </div>
          <div className="col-md-3">
            <label>Chapter name</label>
            <input
              type="text"
              className="form-control"
              placeholder="1. The particle model"
            />
          </div>
        </div>

        <br />

        <div className="col-md-12 d-flex">
          <div className="col-md-3">
            <label>Total No of question</label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-md-3">
            <label>Total duration</label>
            <input
              type="time"
              id="appt"
              name="appt"
              className="form-control"
            />
          </div>
        </div>
        <br />
      </div>
      <div className="col-md-12 d-flex">
        <div className="col-md-2">
          <div className="Worksheet_QuestionType">Question Type</div>
          <div className="Worksheet_QuestionType_data">
            <div id="MCQ" onClick={() => handelHide("mcq")}>
              MCQ
            </div>
            <div id="oneword" onClick={() => handelHide("oneword")}>
              One words
            </div>
            <div id="image" onClick={() => handelHide("image")}>
              Image
            </div>
            {/* <div id="fillinblanks">Fill in blanks</div>
              <div id="matchingpair">Matching pair</div>
              <div id="autocheck">Auto Check</div> */}
          </div>
        </div>
        {tab === "oneword" ? (
          <div className="col-md-10">
            <div className="Selftest_CreateNewQuestions">
              Create New Question
            </div>
            <div className="Selftest_CreateNewQuestions_data">
              <div className="Selftest_Questions_number">
                <div className="col-md-3">
                  <label>Question Number</label>
                  <input
                    type="text"
                    placeholder="1"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="Question_Entry">
                <div className="col-md-12">
                  <label>Question ?</label>
                  {/* <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{
                        width: 880,
                        border: "1px solid #666666",
                        borderRadius: "0px 0px 10px 10px",
                      }}
                    /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Question_Answer">
                <div className="col-md-12">
                  <label>Answer</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="Question_buttons">
                <div className="col-md-12">
                  <button id="solution">Solution</button>
                  <button id="theory">Theory</button>
                </div>
              </div>
              <div className="Question_Entry">
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Selftest_Questions_number">
                <div className="col-md-3">
                  <label>Marks</label>
                  <input
                    type="text"
                    placeholder="2"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="Question_Save_buttons">
                <div className="col-md-12 d-md-flex justify-content-md-end">
                  <button id="save">Save</button>
                  <button id="next_question">Next Question</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {tab === "mcq" ? (
          <div className="col-md-10">
            <div className="Worksheet_CreateNewQuestions">
              Create New Question
            </div>
            <div className="Worksheet_CreateNewQuestions_data">
              <div className="worksheet_Questions_number">
                <div className="col-md-3">
                  <label>Question Number</label>
                  <input
                    type="text"
                    placeholder="1"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="Worksheet_Question_Entry">
                <div className="col-md-12">
                  <label>Question ?</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Worksheet_Question_Answer">
                <label>Answer</label>
                <div className="col-md-12 d-flex">
                  <select value={selected} onChange={handleSelectChange}>
                    <option value="type">Type</option>
                    <option value="image">Image</option>
                    <option value="text">Text</option>
                  </select>
                  <div className="row">
                    {selected == "type" ? (
                      <div className="d-flex">
                        <input type="text" className="form-control" />
                        <label className="toggleSwitch_worksheet nolabel">
                          <input
                            type="checkbox"
                            class="switch_1"
                            name="payment_email_notification"
                          />
                          <span>
                            <span>No</span>
                            <span>Yes</span>
                          </span>
                          <a></a>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {selected == "text" ? (
                      <div className="d-flex">
                        <input type="text" className="form-control" />
                        <label className="toggleSwitch_worksheet nolabel">
                          <input
                            type="checkbox"
                            class="switch_1"
                            name="payment_email_notification"
                          />
                          <span>
                            <span>No</span>
                            <span>Yes</span>
                          </span>
                          <a></a>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {selected == "image" ? (
                      <div className="d-flex">
                        <input type="file" className="form-control" />
                        <label className="toggleSwitch_worksheet nolabel">
                          <input
                            type="checkbox"
                            class="switch_1"
                            name="payment_email_notification"
                          />
                          <span>
                            <span>No</span>
                            <span>Yes</span>
                          </span>
                          <a></a>
                        </label>
                        <i>
                          <MdDeleteOutline />
                        </i>
                      </div>
                    ) : (
                      ""
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                {Array.from(Array(counter)).map((c, index) => {
                  return (
                    <div className="col-md-12 d-flex">
                      <select value={selected} onChange={handleSelectChange}>
                        <option value="type">Type</option>
                        <option value="image">Image</option>
                        <option value="text">Text</option>
                      </select>
                      <div className="row">
                        {selected == "type" ? (
                          <div className="d-flex">
                            <input type="text" className="form-control" />
                            <label className="toggleSwitch_worksheet nolabel">
                              <input
                                type="checkbox"
                                class="switch_1"
                                name="payment_email_notification"
                              />
                              <span>
                                <span>No</span>
                                <span>Yes</span>
                              </span>
                              <a></a>
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        {selected == "text" ? (
                          <div className="d-flex">
                            <input type="text" className="form-control" />
                            <label className="toggleSwitch_worksheet nolabel">
                              <input
                                type="checkbox"
                                class="switch_1"
                                name="payment_email_notification"
                              />
                              <span>
                                <span>No</span>
                                <span>Yes</span>
                              </span>
                              <a></a>
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        {selected == "image" ? (
                          <div className="d-flex">
                            <input type="file" className="form-control" />
                            <label className="toggleSwitch_worksheet nolabel">
                              <input
                                type="checkbox"
                                class="switch_1"
                                name="payment_email_notification"
                              />
                              <span>
                                <span>No</span>
                                <span>Yes</span>
                              </span>
                              <a></a>
                            </label>
                            <i>
                              <MdDeleteOutline />
                            </i>
                          </div>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="Worksheet_Add_Answer">
                <button onClick={handleClickAdd}>Add Answer</button>
              </div>
              <div className="Question_buttons">
                <div className="col-md-12">
                  <button id="solution">Solution</button>
                  <button id="theory">Theory</button>
                </div>
              </div>
              <div className="Question_Entry">
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Selftest_Questions_number">
                <div className="col-md-3">
                  <label>Marks</label>
                  <input
                    type="text"
                    placeholder="2"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="Question_Save_buttons">
                <div className="col-md-12 d-md-flex justify-content-md-end">
                  <button id="save">Save</button>
                  <button id="next_question">Next Question</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {tab === "image" ? (
          <div className="col-md-10">
            <div className="Selftest_CreateNewQuestions">
              Create New Question
            </div>
            <div className="Selftest_CreateNewQuestions_data">
              <div className="Iamage_Questions_number">
                <div className="col-md-8 d-flex ">
                  <div>
                    <label>Question Number</label>
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                  <div>
                    <label>Chapter Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="Image_Question_Entry">
                <div className="col-md-12">
                  <label>Question ?</label>
                  {/* <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{
                        width: 880,
                        border: "1px solid #666666",
                        borderRadius: "0px 0px 10px 10px",
                        marginBottom: "20px",
                      }}
                    /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Image_Question_Entry">
                <div className="col-md-12">
                  <label>Answer</label>
                  {/* <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      wrapperStyle={{
                        width: 880,
                        border: "1px solid #666666",
                        borderRadius: "0px 0px 10px 10px",
                        marginBottom:"20px",
                      }} */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Image_Question_buttons">
                <div className="col-md-12">
                  <button id="solution">Solution</button>
                  <button id="theory">Theory</button>
                  <button id="theory">Marking Scheme</button>
                </div>
              </div>
              <div className="Question_Entry">
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText(data);
                    }}
                  />
                </div>
              </div>
              <div className="Selftest_Questions_number">
                <div className="col-md-3">
                  <label>Marks</label>
                  <input
                    type="text"
                    placeholder="2"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="Question_Save_buttons">
                <div className="col-md-12 d-md-flex justify-content-md-end">
                  <button id="save">Save</button>
                  <button id="next_question">Next Question</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
  )
}

export default MockButtonC