import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Curriculum.css";

import { FiPlusCircle } from "@react-icons/all-files/fi/FiPlusCircle";

import { Link } from "react-router-dom";

function CreateView() {
  const [hideYear, setHideYear] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleCheckBox = (event) => {
    setHideYear(event.target.checked);
  };
  const handleClickAdd = () => {
    setCounter(counter + 1);
  };
  const handleChapterAdd = () => {
    setCounter(counter + 1);
  };
  return (
    <div className="Curriculum_Unit_wrapper">
      <div className="col-md-12  mt-3 d-flex">
        <div className="col-md-6">
          <div className="offset-md  Curriculum_Unit-tag">
            <div className="switch_box2 box_2">
              {hideYear ? <label>Chapter</label> : <label>Unit</label>}
              <input
                type="checkbox"
                className="switch_2"
                onClick={handleCheckBox}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 d-md-flex justify-content-md-end Unit_back_button">
          <Link to="/curriculum">
            {" "}
            <button>Back</button>
          </Link>
        </div>
      </div>
      {hideYear ? (
        <div className="unit_form_wrapper_edit container-fluid">
          <form className=" View_unit">
            <div>
              <div className="col-md-12 d-flex ">
                <div className="col-md-6">
                  <p>View Details</p>
                </div>
                <div className="col-md-6 d-md-flex justify-content-md-end">
                  <button>Edit</button>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-3">
                  <label>Select country</label>
                  <div>
                    <select>
                      <option>Select Country</option>
                      <option value="uk">UK</option>
                      <option value="us">US</option>
                      <option value="india">India</option>
                      <option value="srilanka">Srilanka</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select exam board</label>
                  <div>
                    <select>
                      <option>Exam board</option>
                      <option value="uk">ICSE</option>
                      <option value="us">AQA</option>
                      <option value="india">GCSE</option>
                      <option value="srilanka">CBSE</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select level</label>
                  <div>
                    <select>
                      <option>Select Level</option>
                      <option value="uk">Level 1</option>
                      <option value="us">Level 2</option>
                      <option value="india">Level 3</option>
                      <option value="srilanka">Level 4</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex unit_form_wrapper1">
                <div className="col-md-3">
                  <label>Select grade</label>
                  <div>
                    <select>
                      <option>Select Grade</option>
                      <option value="uk">Grade 1</option>
                      <option value="us">Grade 2</option>
                      <option value="india">Grade 3</option>
                      <option value="srilanka">Grade 4</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select Subject</label>
                  <div>
                    <select>
                      <option>Subject</option>
                      <option value="uk">Chemistry</option>
                      <option value="us">Physics</option>
                      <option value="india">Maths</option>
                      <option value="srilanka">Social</option>
                    </select>
                  </div>
                </div>
              </div>
              {Array.from(Array(counter)).map((c, index) => {
                return (
                  <div className="col-md-12 d-flex unit_form_wrapper1">
                    <div className="col-md-6">
                      <label>Enter Chapter no & more</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Elements compounds and mixtures"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Lesson plan</label>
                      <div>
                        <input
                          type="file"
                          className="form-control"
                          placeholder="Unit 1: Principle of chemistry"
                        />
                        <i onClick={handleChapterAdd}>
                          {" "}
                          <FiPlusCircle />
                        </i>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="col-md-12 d-flex unit_form_wrapper1">
                <div className="col-md-6">
                  <label>Enter Chapter no & more</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Elements compounds and mixtures"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Lesson plan</label>
                  <div>
                    <input
                      type="file"
                      className="form-control"
                      placeholder="Unit 1: Principle of chemistry"
                    />
                    <i onClick={handleChapterAdd}>
                      {" "}
                      <FiPlusCircle />
                    </i>
                  </div>
                </div>
              </div>
              <div className="unit_Save_button d-md-flex justify-content-md-end">
                <button>Save</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="unit_form_wrapper_edit container-fluid">
          <div>
            <form className=" View_unit">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <p>View Details</p>
                </div>
                <div className="col-md-6 d-md-flex justify-content-md-end">
                  <button>Edit</button>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-3">
                  <label>Select country</label>
                  <div>
                    <select>
                      <option>Select Country</option>
                      <option value="uk">UK</option>
                      <option value="us">US</option>
                      <option value="india">India</option>
                      <option value="srilanka">Srilanka</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select exam board</label>
                  <div>
                    <select>
                      <option>Exam board</option>
                      <option value="uk">ICSE</option>
                      <option value="us">AQA</option>
                      <option value="india">GCSE</option>
                      <option value="srilanka">CBSE</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select level</label>
                  <div>
                    <select>
                      <option>Select Level</option>
                      <option value="uk">Level 1</option>
                      <option value="us">Level 2</option>
                      <option value="india">Level 3</option>
                      <option value="srilanka">Level 4</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Select grade</label>
                  <div>
                    <select>
                      <option>Select Grade</option>
                      <option value="uk">Grade 1</option>
                      <option value="us">Grade 2</option>
                      <option value="india">Grade 3</option>
                      <option value="srilanka">Grade 4</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex unit_form_wrapper1">
                <div className="col-md-3">
                  <label>Select Subject</label>
                  <div>
                    <select>
                      <option>Subject</option>
                      <option value="uk">Chemistry</option>
                      <option value="us">Physics</option>
                      <option value="india">Maths</option>
                      <option value="srilanka">Social</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Enter Unit name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Unit 1: Principle of chemistry"
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex unit_form_wrapper1">
                <div className="col-md-6">
                  <label>Enter Chapter no & more</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Elements compounds and mixtures"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Lesson plan</label>
                  <div>
                    <input
                      type="file"
                      className="form-control"
                      placeholder="Unit 1: Principle of chemistry"
                    />
                    <i onClick={handleClickAdd}>
                      {" "}
                      <FiPlusCircle />
                    </i>
                  </div>
                </div>
              </div>
              {Array.from(Array(counter)).map((c, index) => {
                return (
                  <div className="col-md-12 d-flex unit_form_wrapper1">
                    <div className="col-md-6">
                      <label>Enter Chapter no & more</label>
                      <div>
                        <input
                          type="text"
                          key={c}
                          className="form-control"
                          placeholder="Elements compounds and mixtures"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Lesson plan</label>
                      <div>
                        <input
                          type="file"
                          key={c}
                          className="form-control"
                          placeholder="Unit 1: Principle of chemistry"
                        />
                        <i onClick={handleClickAdd}>
                          {" "}
                          <FiPlusCircle />
                        </i>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="unit_Save_button d-md-flex justify-content-md-end">
                <button>Save</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateView;
