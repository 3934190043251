import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/SelfTest.css";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import {Link } from "react-router-dom";

function SelfTest() {
  
  return (
    <div className="SelfTest_wrapper">
      <div className="col-md-12">
        <h5>SelfTest</h5>
      </div>
      <div className="Selftest_wrapper_table">
        <div className="col-md-12">
          <form className="d-flex Searchbar">
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="country"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Examboard"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Grade"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Subject"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Chapter"
            />
          </form>
          <div className="Selftest_table">
            <table className="table" id="Selftest_table">
              <tr>
                <th>Number</th>
                <th>Lesson name</th>
                <th>Demo</th>
                <th>Selftest</th>
                <th></th>
              </tr>

              <tr>
                <td>
                  <span>1.1</span>
                </td>
                <td>The Praticlals</td>
                <td>
                  <span>
                    <button id="selftest_demo_button">Demo</button>
                  </span>
                </td>
                <td>
                  <Link to="/activityselftestcreate">
                  <button
                    id="selftest_create_button"
                  >
                    Create
                  </button></Link>
                </td>
                <td>
                  <label className="toggleSwitch nolabel">
                    <input
                      type="checkbox"
                      className="switch_1"
                      name="payment_email_notification"
                    />
                    <span>
                      <span>Draft</span>
                      <span>Publish</span>
                    </span>
                    <a></a>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfTest;
