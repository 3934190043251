import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Chapterpaper.css";

import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";

import { Link } from "react-router-dom";

function Chapterpaper() {
  return (
    <div className="Chapterpaper_wrapper">
      <div className="col-md-12">
        <h5>Chapter paper</h5>
      </div>
      <div className="Chapterpaper_wrapper_table">
        <div className="col-md-12">
          <form className="d-flex Searchbar">
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Examboard"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Grade"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Subject"
            />
            <div className="serch-icon">
              <AiOutlineSearch />
            </div>
            <input
              className="form-control "
              type="search"
              aria-label="Search"
              placeholder="Chapter"
            />
          </form>
          <div className="Chapterpaper_table">
            <table className="table" id="Chapterpaper_table">
              <tr>
                <th></th>
                <th>Chapter Name</th>
                <th>Subject</th>
                <th>Grade</th>
                <th>Exam board</th>
                <th>Demo</th>
              </tr>

              <tr>
                <td id="Chapterpaper_Color">
                 <img src=""alt=""/>
                </td>
                <td>01. The particle models</td>
                <td>
                  <span id="mocktestsubject">Chemistry</span>
                </td>
                <td>G3</td>
                <td>AQA</td>
                <td id="Chapterpaper_button">
                  <Link to="/activitychapterdemo">
                    <button>Demo</button>
                  </Link>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chapterpaper;
