import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Pastpaper.css";
import { Link } from "react-router-dom";

import { GrEdit } from "@react-icons/all-files/gr/GrEdit";
import {BiEditAlt} from "react-icons/bi";

function DemoSelftestA() {
  const [hideLightbox, setHideLightbox] = useState(true);
  return (
    <div className="Demo_Wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <div className="demo_selftest d-flex">
              <div className="col-md-3 d-flex">
                <h6>Qus Time</h6>
                <time>00:40</time>
              </div>
              <div className="col-md-6">
                <ul id="pagination" className=" d-flex">
                  <li onClick={() => setHideLightbox(false)}>
                    <a href="#" className="active">
                      1
                    </a>
                    <ul
                      className={`lightbox ${
                        hideLightbox ? "hide-lightbox" : ""
                      }`}
                      style={{ display: "flex" }}
                    >
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">2</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">3</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">4</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">5</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">6</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">7</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">8</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">9</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">10</a>
                    <ul className="inline_pagination d-flex">
                      <li>
                        <a href="#">a</a>
                      </li>
                      <li>
                        <a href="#">b</a>
                      </li>
                      <li>
                        <a href="#">c</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="col-md-3 ">
                <div className="Demo_Close_button d-md-flex justify-content-md-end">
                  <i>
                  <BiEditAlt/>
                  </i>
                  <Link to="/activitypastpaper">
                    {" "}
                    <button id="Close_button">Close</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="demo_selftest_questiona">
            <h6>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Birds have different
              shaped beaks. The shape of the bird’s beak is suited to the food
              the bird eats.
            </h6>
            <p>
              Draw THREE lines to match each beak to the food the bird eats.
            </p>
            <div id="demo_selftest_questiona">
              <img src="\Super Admin Images\questi2.png" alt="" />
              <h6 className="d-md-flex justify-content-md-end">(1 mark)</h6>
            </div>
          </div>
        </div>
        <div className="demo_selftest_nexta d-md-flex justify-content-md-end">
          <button>
            <Link to="/activitydemoselftest">Back</Link>
          </button>
          <button>
            <Link to="#">Next</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DemoSelftestA;
